import { FC, useEffect, useState } from 'react';

import { AnimatePresence, motion } from 'framer-motion';
import { NextPage } from 'next';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { Settings } from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
//Import css files
import 'slick-carousel/slick/slick.css';

import logo from '@assets/images/logo.png';
import AppStoreSvg from '@assets/svg/appStore.svg';
import GooglePlaySvg from '@assets/svg/googlePlay.svg';
import LogoText from '@assets/svg/logoText-1.5x.svg';
import Rating from '@assets/svg/rating.svg';
import landingPageData from '@configs/landingPage.config';
import { useGetRecommendations, useGetUser } from '@hooks/queries';
import useAnalyticsManager from '@hooks/useAnalyticsManager';
// import ky from "ky";
import { LandingPageData, Main, Stat } from '@libs/types';

const Slider = dynamic(() => import('react-slick'));

const variants = {
  initial: {
    bottom: -100,
  },
  animate: {
    bottom: 0,
    transition: {
      duration: 0.35,
    },
  },
  exit: {
    bottom: -100,
    transition: {
      duration: 0.35,
    },
  },
};

const Landing: NextPage<{ data: LandingPageData }> = ({ data }) => {
  const { main, stats, carouselItems, footer } = data;

  return (
    <div className="w-screen overflow-scroll bg-white">
      <div className="px-0 xl:px-[280px] bg-[#FFF9FB] lg:px-[100px]  md:px-[50px]">
        <NavBar />
        <MainSection main={main} stats={stats} />
      </div>
      <CarouselSection carouselItems={carouselItems} />
      <RatingSection />
      <FooterSection footer={footer} />
    </div>
  );
};

export default Landing;

const MainSection: FC<{ main: Main; stats: Stat[] }> = ({ main, stats }) => {
  const analyticsManager = useAnalyticsManager();

  const {
    error: fetchUserError,
    isLoading,
    isSuccess: isUserAuthenticated,
  } = useGetUser({
    onSuccess: null,
    retry: (failureCount, error) => {
      // @ts-ignore :  type of error ?
      if (failureCount < 3 && error?.response?.status >= 500) return true; // retry on 5xx errors for 3 times
    },
  });

  useGetRecommendations({
    enabled: !isLoading && !fetchUserError,
  });

  const router = useRouter();
  // eslint-disable-next-line
  const [showStickyButton, setShowStickyButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const y = window.pageYOffset || document.documentElement.scrollTop;
      if (y > 626) setShowStickyButton(true);
      else setShowStickyButton(false);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    router.prefetch('/onboarding');
    router.prefetch('/ai/matches');
  }, []);

  useEffect(() => {
    analyticsManager.sendEvent('landingpage_screen_web', null, ['mixpanel']);
  }, [analyticsManager.isMixpanelLoaded]);

  const handleCreateAccountButtonClick = () => {
    analyticsManager.sendEvent('signup_started_web', { cleverTap: 'yes' }, 'all');
    router.push(isUserAuthenticated ? '/ai/matches' : '/onboarding');
  };

  return (
    <div className="h-[567px]  md:h-auto  flex flex-col justify-around ">
      <div>
        <main className="grid grid-cols-2 gap-6 ">
          <div className="flex flex-col items-center justify-center col-span-2 space-y-14 md:items-stretch md:col-span-1">
            <div className="flex flex-col items-center space-y-2 md:items-stretch">
              <h1 className="text-2xl font-bold !leading-tight text-center md:text-4xl lg:text-5xl md:text-left max-w-[300px] md:max-w-[400px]">
                {main.title}
              </h1>
              <p className="text-lg leading-6 text-[16px] text-center text-gray-500 md:text-xl lg:text-2xl md:text-left max-w-[300px] md:max-w-[400px]">
                {main.subTitle}
              </p>
            </div>
            <button
              id="webapp-ca-btn"
              className="  w-[250px] hidden md:block md:w-[300px] md:p-4  lg:w-[382px]  p-4 lg:p-6 text-2xl text-white bg-black rounded-full word-spacing"
              onClick={handleCreateAccountButtonClick}
            >
              {main.buttonText}
            </button>
          </div>

          <div className="relative w-full h-[290px] md:h-[560px] col-span-2 md:col-span-1 ">
            <Image
              src={main.heroImageUrl}
              alt="wedding image"
              layout="fill"
              objectFit="contain"
              quality={100}
              priority
              loading="eager"
            />
          </div>
        </main>

        <StatsSection stats={stats} />
      </div>
      {/* //! These two buttons will only be visible in the mobile view */}
      <button
        id="webapp-ca-btn"
        className="word-spacing  w-[80vw] block mx-auto md:hidden  md:w-[402px]  p-4 md:p-6 text-xl text-white bg-black rounded-full"
        onClick={handleCreateAccountButtonClick}
      >
        {main.buttonText}
      </button>
      <AnimatePresence>
        {showStickyButton && (
          <motion.div
            variants={variants}
            initial="initial"
            animate="animate"
            exit="exit"
            className="gradient-white-transparent-bg text-center  w-full xl:hidden pb-2 -bottom-5  xl:w-[402px] fixed  z-50 left-1/2 transform -translate-x-1/2 "
          >
            <button
              id="webapp-ca-btn"
              className="word-spacing  w-[80vw]     shadow  p-4 xl:p-6 text-xl text-white bg-black rounded-full"
              onClick={handleCreateAccountButtonClick}
            >
              {main.buttonText}
            </button>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
const NavBar = () => {
  return (
    <nav className="h-[30px] my-4 mb-5 xl:h-auto grid place-items-center xl:block xl:text-left  ">
      <LogoText />
    </nav>
  );
};

const StatsSection = ({ stats }) => {
  return (
    <div className="flex mb-2 space-x-2 text-white xl:mt-20 sm:space-x-6" id="stats">
      {stats.map((stat, index) => (
        <div
          key={index}
          className="flex flex-col items-center justify-center w-1/2 py-2 md:rounded-xl xl:py-9"
        >
          <h1 className="text-xl font-bold sm:text-4xl">{stat.number}</h1>
          <p className="text-bases sm:text-2xl">{stat.text}</p>
        </div>
      ))}
    </div>
  );
};

const RatingSection = () => (
  <>
    <div className="flex justify-center items-center h-auto  pt-4 pb-6  bg-[#FFF9FB]">
      <div className="h-[152px] w-[312px] xl:h-[312px] xl:w-[634px]">
        <Rating />
      </div>
    </div>
    {/* ----- */}
    <div className="flex items-center justify-center py-6 space-x-4 xl:py-10 ">
      <button className="h-12 w-[160px] xl:h-[75px] xl:w-[252px]">
        <GooglePlaySvg />
      </button>
      <button className="h-12 w-[136px] xl:h-[75px] xl:w-[214px]">
        <AppStoreSvg />
      </button>
    </div>
  </>
);

const FooterSection = ({ footer }) => (
  <footer className="flex flex-col items-center justify-center mb-20 xl:mb-0">
    <div className="relative w-40 h-20">
      <Image src={logo} alt="logo" objectFit="contain" layout="fill" quality={100} />
    </div>
    <p className="w-full p-4 text-xs text-center text-white sm:text-lg bg-primaryPink">
      {footer.text}
    </p>
  </footer>
);

const CarouselSection = ({ carouselItems }) => {
  const settings: Settings = {
    dots: true,
    dotsClass: 'slick-dots slick-thumb slick-dots-custom',
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 3,

    // autoplay: true,
    autoplaySpeed: 3000,

    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.03,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  return (
    <div className="my-6 space-y-6 xl:my-14 xl:px-[250px] px-2">
      <h2 className=" text-center text-[24px] xl:text-4xl">Met on Betterhalf</h2>

      <Slider {...settings}>
        {carouselItems.map((item, index) => (
          <CarouselItem item={item} key={index} index={index} />
        ))}
      </Slider>
    </div>
  );
};

const CarouselItem = ({ item, index }) => {
  return (
    <div
      className={`flex flex-col h-[395px]  md:h-[470px] items-center w-full overflow-hidden border xl:w-full border-grayShadow rounded-2xl `}
      id={`carouselItem-${index}`}
    >
      <div className="relative w-full">
        <Image
          src={item.imageUrl}
          alt="wedding image"
          layout="responsive"
          objectFit="cover"
          className="overflow-hidden"
          width={315}
          height={237}
          priority
        />
      </div>
      <h5 className="p-2 w-full text-center bg-[#F0E9FF]">{item.title}</h5>
      <p className="flex items-center flex-1 w-full p-2 text-xs text-center xl:text-base text-grayIcon">
        {item.subTitle}
      </p>
    </div>
  );
};

export async function getStaticProps() {
  return {
    props: {
      data: landingPageData,
    },
  };
}
